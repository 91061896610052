import React from "react";

export default () => (
  <div>
    WOCH Terms of Service Disclaimer: These Terms of Service (“Terms” or “Agreement”) govern the
    access or use by you, an individual user or viewer of applications, websites, content, products,
    and services (the “Services”) made available by WOCH (“WOCH, “Us”, or “We”). WOCH owns the
    domain and content located at https://woch.io/. By using the website, the related mobile
    website, and any mobile application (collectively, the “Websites”), you agree to be bound by
    these Terms of Service, whether or not you register as a member of WOCH ("Member"). If you wish
    to become a Member and/or make use of the Service (the “Service”), please read this Agreement.
    If you object to anything in this Agreement or the WOCH Privacy Policy, do not use the Service.
    Your access and use of the Services constitute your agreement to be bound by these Terms, which
    establishes a contractual relationship between you and WOCH. If you do not agree to these Terms,
    you may not access or use the Services. These Terms expressly supersede prior agreements or
    arrangements with you. WOCH may immediately terminate or modify these Terms or any Services with
    respect to you, or generally cease offering or deny access to the Services or any portion
    thereof, at any time for any reason, effective upon posting on the relevant Website. Your
    continued use of the Websites and the Service following WOCH posting of revised terms of any
    section of the Agreement will constitute your express and binding acceptance of and consent to
    the revised Agreement. PLEASE READ THIS AGREEMENT CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION
    REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS, INCLUDING VARIOUS LIMITATIONS AND
    EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED.
    Electronic Agreement. This Agreement is an electronic contract that sets out the legally binding
    terms of your use of the Websites and the Service. This Agreement may be modified by WOCH from
    time to time, such modifications to be effective upon posting by WOCH on the Websites. By
    accessing and/or using the Websites or becoming a Member, you accept this Agreement and agree to
    the terms, conditions and notices contained or referenced herein. 1. Access and Retention In
    order to access and retain this electronic Agreement, you must be at least eighteen years of age
    or the age of legal majority in your jurisdiction (if different than 18), or have permission by
    a parent or legal guardian. You must have access to the Internet, either directly or through
    devices that access web-based content, and pay any service fees associated with such access.
    Account registration requires you to submit to WOCH certain personal information, such as your
    full name, e-mail, billing address, as well as at least one valid payment method (either a
    credit card or accepted payment partner). Your payment information is stored on the secured
    third-party platform, STRIPE, and users are encouraged to read and review Stripe’s terms of
    service and privacy policies. You acknowledge that WOCH is not liable for the safety and
    security of payments and payment information through any third-party platforms. You agree to
    maintain accurate, complete, and up-to-date information in your Account. Your failure to
    maintain accurate, complete, and up-to-date Account information, including having an invalid or
    expired payment method on file, may result in your inability to access and use the Services or
    WOCH’s termination of these Terms with you. You are responsible for all activity that occurs
    under your Account, and you agree to maintain the security and secrecy of your Account username
    and password at all times. By posting or submitting any material on or through our Websites such
    as comments, posts, photos, images or videos or other contributions, you are representing that
    you are the owner of all such materials and that you are at least 18 years old. 2. Commercial
    Use of Service If you are using the Service and/or accessing the Websites on behalf of a
    company, entity, or organization (collectively, a “Subscribing Entity”), you represent and
    warrant that: a. You are an authorized representative of the Subscribing Entity, and that you
    have the authority to bind the Subscribing Entity to this Terms of Service; b. You have read and
    understand this Terms of Service c. You agree to this Terms of Service on behalf of the
    Subscribing Entity. Use of the Websites and Service is with the permission of WOCH, which may be
    revoked at any time, for any reason, in WOCH’s sole discretion. 3. Account Security You are
    responsible for maintaining the confidentiality of the username and password that you designate
    during the registration process, and you are fully responsible for all activities that occur
    under your username and password. You agree to (a) immediately notify WOCH of any unauthorized
    use of your username or password or any other breach of security (b) ensure that you exit from
    your account at the end of each session. WOCH will not be liable for any loss or damage arising
    from your failure to comply with this provision. You should use particular caution when
    accessing your account from a public or shared computer so that others are not able to view or
    record your password or other personal information. If you share your computer with others, you
    may wish to consider disabling your auto-login feature if you have it linked to your WOCH
    account. 4. Your Use of the Websites a. You may not browse or download illegal content. b. You
    must not copy or capture, or attempt to copy or capture, any content from the Websites (the
    “Content”) or any part of the Websites, unless given express permission by WOCH. c. You must not
    copy, republish, adapt, make available or otherwise communicate to the public, display, perform,
    transfer, share, distribute or otherwise use or exploit any Content on or from the Platform,
    except (i) where such Content is created by you (such content, “Your Content”), or (ii) as
    permitted under these Terms of Service, and within the parameters set by the person or entity
    that uploaded the Content (the “Uploader”). d. You must not use any Content (other than Your
    Content) in any way that is designed to create a separate content service or that replicates any
    part of the Websites’ offering. e. You must not employ scraping or similar techniques to
    aggregate, repurpose, republish or otherwise make use of any Content. f. You must not employ the
    use of bots, botnets, scripts, apps, plugins, extensions or other automated means to register
    accounts, log-in, post comments, or otherwise to act on your behalf, particularly where such
    activity occurs in a multiple or repetitive fashion. You must not offer or promote the
    availability of any such techniques or services to any other users of the Websites. g. You must
    not alter or remove, or attempt to alter or remove, any trademark, copyright or other
    proprietary or legal notices contained in, or appearing on, the Websites or any Content
    appearing on the Websites (other than Your Content). h. You must not, and must not permit any
    third party to, copy or adapt the object code of the Websites, or reverse engineer, reverse
    assemble, decompile, modify or attempt to discover any source or object code of any part of the
    Websites, or circumvent or attempt to circumvent or copy any copy protection mechanism or access
    any rights management information pertaining to Content other than Your Content. i. You must not
    use the Websites to upload, post, store, transmit, display, copy, distribute, promote, make
    available or otherwise communicate to the public:  ● any Content that is offensive, abusive,
    libelous, defamatory, obscene, racist, sexually explicit, ethnically or culturally offensive,
    indecent, that promotes violence, terrorism, or illegal acts, incites hatred on grounds of race,
    gender, religion or sexual orientation, or is otherwise objectionable and unethical in WOCH’s
    sole and reasonable discretion;   ● any information, Content or other material that violates,
    plagiarizes, misappropriates or infringes the rights of third parties including, without
    limitation, copyright, trademark rights, rights of privacy or publicity, confidential
    information or any other right; or   ● any Content that violates, breaches or is contrary to any
    law, rule, regulation, court order or is otherwise is illegal or unlawful in WOCH’s sole and
    reasonable opinion;   ● any material of any kind that contains any virus, Trojan horse, spyware,
    adware, malware, bot, time bomb, worm, or other harmful or malicious component, which will or
    might overburden, impair or disrupt the Websites or servers or networks forming part of, or
    connected to, the Websites, or which does or might restrict or inhibit any other user's use and
    enjoyment of the Websites; or   ● any unsolicited or unauthorized advertising, promotional
    messages, spam or any other form of solicitation. j. You must not commit or engage in, or
    encourage, induce, solicit or promote, any conduct that would constitute a criminal offense,
    give rise to civil liability or otherwise violate any law or regulation. k. You must not rent,
    sell or lease access to the Websites, or any Content on the Websites, although this will not
    prevent you from including links from Your Content to any legitimate online download store from
    where any item of Your Content may be purchased. l. You must not deliberately impersonate any
    person or entity or otherwise misrepresent your affiliation with a person or entity, for
    example, by registering an account in the name of another person or company, or sending messages
    or making comments using the name of another person. m. You must not stalk, exploit, threaten,
    abuse or otherwise harass another user, or any WOCH employee or agent. If we feel that your
    behavior towards any of our employees is at any time threatening or offensive, we reserve the
    right to immediately terminate your membership and you will not be entitled to any refund. n.
    You must not sell or transfer, or offer to sell or transfer, any WOCH account to any third party
    without the prior written approval of WOCH. o. You must not collect or attempt to collect
    personal data, or any other kind of information about other users, including without limitation,
    through spidering or any form of scraping. p. You must not violate, circumvent or attempt to
    violate or circumvent any data security measures employed by WOCH or any Uploader; access or
    attempt to access data or materials which are not intended for your use; log into, or attempt to
    log into, a server or account which you are not authorized to access; attempt to scan or test
    the vulnerability of WOCH’s servers, system or network or attempt to breach WOCH’s data security
    or authentication procedures; attempt to interfere with the Websites or the Services by any
    means including, without limitation, hacking WOCH’s servers or systems, submitting a virus,
    overloading, mail-bombing or crashing. Without limitation to any other rights or remedies of
    WOCH under these Terms of Service, WOCH reserves the right to investigate any situation that
    appears to involve any of the above, and may report such matters to, and cooperate with,
    appropriate law enforcement authorities in prosecuting any users who have participated in any
    such violations. q. You must not act in any way that may violate integrated platfoms on the
    Website including but not limited to; Discord, Stripe or any other Third-Party Service’s Terms
    of Service and Privacy Policies. WOCH has no responsibility or liability for any User’s conduct
    or misconduct on any Third-Party Platform, including but not limited to Discord and Stripe. 5.
    Services & Rules WOCH Members will receive exclusive access to the chat group facilitated
    through the online platform, Discord. Membership provides information, tools, and education on
    obtaining shoes, clothing and other goods for retail. The chat group allows on-going support
    within the WOCH membership group. WOCH provides no express or implied warranty or representation
    of any kind that members will sell goods or make profit. (See Section 15 below). WOCH is not
    responsible for the terms of service or policies of any third-party platform or third-party
    vendor, nor is WOCH responsible for the acts, omissions, or liability of any third-party.
    Members must abide by the WOCH Discord Chat rules set out in the discord server. 6. Payments and
    Refunds Payments are made through Stripe, a third-party application, via credit or debit card.
    There are NO REFUNDS. Stripe is a secured payment platform and for the terms of service and
    privacy policy please visit the Stripe website directly. 7. Cancellation Policy WOCH is
    committed to providing an world class service and experience. If you wish to no longer use woch
    services please cancel your subscription by visiting emailing help@woch.io and deactivating your
    subscription. The billing of your subscription will be cancelled immediately, you will still
    continue to have access to your all features and services until your billing term has ended, a
    grace period. During this period you may reactivate your subscription. Upon dispute if you have
    failed to utilise all the above approaches to cancel your subscription we are unable to issue a
    refund because we deem significant effort has not been made, to cancel the service. We maintain
    logs of all cancellation requests, in the event of a dispute if you have not made a significant
    effort to cancel the subscription, we will provide this evidence to the mediator/handler of such
    dispute. 8. Dispute Resolution Policy WOCH is committed to providing an exceptional service. If
    a dispute arises out of, or in connection with this Agreement, the parties agree to meet in
    person (or via online video conference) with an objective third party to act as the mediator. If
    the dispute cannot be resolved after 30 days of mediation, and in the event of legal action, the
    prevailing party shall be entitled to recover all legal fees and court costs from the other
    party. You agree to comply with the above conditions, and acknowledge and agree that WOCH has
    the right, in its sole discretion, to terminate your account or take such other action as we see
    fit if you breach any of the above conditions or any of the other terms of these Terms of
    Service. This may include taking court action and/or reporting offending users to the relevant
    authorities. 9. Term and Termination This Agreement will remain in full force and effect while
    you use the Websites and/or Service. You may terminate your membership and/or subscription at
    any time by contacting us at help@woch.io. If you resign or cancel your membership and/or
    subscription to WOCH, to help us to analyze and improve the Service, you may be asked to provide
    a reason for your resignation/cancellation. WOCH may terminate your membership and/or
    subscription for any reason by sending notice to you at the email address you provide in your
    application for membership, or such other email address as you may later provide to WOCH. If
    WOCH terminates your membership in the Service because you have breached this Agreement, you
    will not be entitled to any refund. All decisions regarding the termination of accounts shall be
    made in the sole discretion of WOCH. WOCH is not required to provide you notice prior to
    terminating your membership and/or subscription. WOCH is not required, and may be prohibited,
    from disclosing a reason for the termination of your account. Even after your membership or
    subscription is terminated, this Agreement will remain in effect. All terms that by their nature
    may survive termination of this Agreement shall be deemed to survive such termination. 10.
    Modifications to Service WOCH reserves the right at any time to modify or discontinue,
    temporarily or permanently, the Service (or any part thereof) with or without notice. You agree
    that WOCH shall not be liable to you or to any third party for any modification, suspension or
    discontinuance of the Service. 11. Blocking of IP Addresses In order to protect the integrity of
    the Services, WOCH reserves the right at any time in its sole discretion to block Members from
    certain IP addresses from accessing the Websites. 12. Headings The section headings contained in
    this Agreement are for reference purposes only and shall not in any way affect the meaning or
    interpretation of this Agreement. If you have any questions about these Terms of Service, please
    contact us at help@woch.io. Last Updated: June 2020 BY USING THE WEBSITE(S) YOU AGREE THAT YOU
    HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.
  </div>
);
